<template>
    <div :data-invalid="error">
        <input type="file" :id="id" @change="onChange" ref="input" :data-added="added" :required="required">
        <ActionButton icon="times" :small="true" @click="remove" :submit="false"/>
        <label :for="id" :data-file="file" :data-label="label"><Icon name="paperclip"/></label>
    </div>
</template>

<script>
import Icon from '@/components/Icon.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';

export default {
    name: 'Upload',
    components: {
        Icon,
        ActionButton,
    },
    setup(props) {
        const id = btoa(props.label);
        return { id };
    },
    props: {
        label: {
            type: String,
            default: 'Vedhæft',
        },
        required: {
            type: Boolean,
            default: false,
        },
        modelValue: [File, Object],
    },
    data() {
        return {
            added: false,
            file: '',
            error: null,
        };
    },
    watch: {
        modelValue(value) {
            this.added = !!value;
            this.file = value?.name;
            this.files = value;
        },
    },
    methods: {
        onChange(e) {
            const input = e.target;
            this.validate();
            this.$emit('update:modelValue', input.files[input.files.length - 1]);
        },
        remove() {
            this.validate();
            this.$emit('update:modelValue', null);
        },
        validate() {
            if (this.required) {
                this.error = this.added ? null : this.$refs.input.validationMessage;
            }
        },
    },
};
</script>
