import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import CreateProject from '@/views/create/Project.vue';
import { auth } from '@/utils/firebase';
import useState from '@/store';
import { projectsCollection } from '@/utils/collections';

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home,
		meta: {
			requiresAuth: true,
		},
		children: [
			{
				path: '',
				name: 'dashboard',
				component: () => import('../views/Dashboard.vue'),
				meta: {
					id: 'dashboard',
					title: 'Dashboard',
					placement: 'menu',
					order: 1,
					icon: 'tachometer',
					requiresProject: true,
				},
			},
			{
				path: '/budget',
				name: 'budget',
				component: () => import('../views/Budget.vue'),
				meta: {
					id: 'budget',
					title: 'Budget',
					placement: 'menu',
					order: 2,
					icon: 'coins',
					requiresProject: true,
				},
				children: [
					{
						name: 'create-budget',
						path: 'create',
						component: () => import('../views/create/BudgetPost.vue'),
						meta: {
							requiresSubscription: true,
						},
					},
					{
						name: 'create-expense',
						path: 'create-expense',
						component: () => import('../views/create/Expense.vue'),
						meta: {
							requiresSubscription: true,
						},
					},
					{
						name: 'view-expense',
						path: ':budget/expenses/:id',
						component: () => import('../views/view/Expense.vue'),
					},
					{
						name: 'view-budget',
						path: ':id',
						component: () => import('../views/view/BudgetPost.vue'),
					},
					{
						name: 'edit-budget',
						path: 'edit/:id',
						component: () => import('../views/create/BudgetPost.vue'),
						meta: {
							requiresSubscription: true,
						},
					},
				],
			},
			{
				path: '/contracts',
				name: 'contracts',
				component: () => import('../views/Contracts.vue'),
				meta: {
					id: 'contracts',
					title: 'Kontrakter',
					placement: 'menu',
					order: 3,
					icon: 'contract',
					requiresProject: true,
				},
				children: [
					{
						name: 'create-contract',
						path: 'create',
						component: () => import('../views/create/Contract.vue'),
						meta: {
							requiresSubscription: true,
						},
					},
					{
						name: 'edit-contract',
						path: 'edit/:id',
						component: () => import('../views/create/Contract.vue'),
						meta: {
							requiresSubscription: true,
						},
					},
					{
						name: 'view-contract',
						path: ':id',
						component: () => import('../views/view/Contract.vue'),
					},
					{
						name: 'create-addition',
						path: ':contract/additions/create',
						component: () => import('../views/create/Addition.vue'),
						meta: {
							requiresSubscription: true,
						},
					},
					{
						name: 'edit-addition',
						path: ':contract/additions/:id/edit',
						component: () => import('../views/create/Addition.vue'),
						meta: {
							requiresSubscription: true,
						},
					},
					{
						name: 'view-addition',
						path: ':contract/additions/:id',
						component: () => import('../views/view/Addition.vue'),
					},
				],
			},
			{
				path: '/timeline',
				name: 'timeline',
				component: () => import('../views/Timeline.vue'),
				meta: {
					id: 'timeline',
					title: 'Tidsplan',
					placement: 'menu',
					order: 4,
					icon: 'calendar',
					requiresProject: true,
				},
				children: [
					{
						path: 'create',
						component: () => import('../views/create/Activity.vue'),
						meta: {
							requiresSubscription: true,
						},
					},
					{
						name: 'edit-timeline',
						path: 'edit/:id',
						component: () => import('../views/create/Activity.vue'),
					},
				],
			},
			{
				path: '/questions',
				name: 'questions',
				component: () => import('../views/Questions.vue'),
				meta: {
					id: 'questions',
					title: 'Spørgsmål',
					placement: 'menu',
					order: 5,
					icon: 'bell',
					requiresProject: true,
				},
				children: [
					{
						name: 'create-question',
						path: 'create',
						component: () => import('../views/create/Question.vue'),
						meta: {
							requiresSubscription: true,
						},
					},
					{
						name: 'view-question',
						path: ':id',
						component: () => import('../views/view/Question.vue'),
					},
				],
			},
			{
				path: '/projects',
				name: 'projects',
				component: () => import('../views/Projects.vue'),
				meta: {
					title: 'Projekter',
					icon: 'coins',
				},
			},
			{
				path: '/projects/:id',
				name: 'view-project',
				component: () => import('../views/create/Project.vue'),
				meta: {
					title: 'Rediger projekt',
					requiresProject: true,
					requiresSubscription: true,
				},
			},
			{
				path: '/projects/create',
				name: 'create-project',
				component: CreateProject,
				meta: {
					title: 'Tilføj nyt projekt',
					requiresSubscription: true,
				},
			},
			{
				path: '/settings',
				name: 'settings',
				component: () => import('../views/Settings.vue'),
				meta: {
					title: 'Indstillinger',
					icon: 'cog',
				},
				children: [
					// {
					//     path: 'update-payment',
					//     name: 'update-payment',
					//     component: () => import('../views/view/UpdatePayment'),
					// },
					// {
					//     path: 'subscription',
					//     name: 'view-subscription',
					//     component: () => import('../views/view/Subscription'),
					// },
				],
			},
			{
				path: '/settings/profile',
				name: 'edit-profile',
				component: () => import('../views/view/EditProfile.vue'),
			},
		],
	},
	{
		path: '/admin',
		name: 'admin',
		component: () => import('../views/admin/Home.vue'),
		meta: {
			requiresAdminAuth: true,
		},
		children: [
			{
				path: '',
				name: 'admin-dashboard',
				component: () => import('../views/admin/Dashboard.vue'),
				meta: {
					id: 'admin-dashboard',
					title: 'Dashboard',
					placement: 'admin',
					icon: 'tachometer',
					order: 1,
				},
			},
			{
				path: 'users',
				name: 'admin-users',
				component: () => import('../views/admin/Users.vue'),
				meta: {
					id: 'admin-users',
					title: 'Brugere',
					placement: 'admin',
					icon: 'users',
					order: 2,
				},
				children: [
					{
						name: 'view-user',
						path: ':id',
						component: () => import('../views/admin/view/User.vue'),
					},
				],
			},
		],
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/Login.vue'),
		meta: {
			title: 'Log ind',
			requiresUnAuth: true,
			loginTemplate: true,
		},
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('../views/Register.vue'),
		meta: {
			title: 'Opret bruger',
			requiresUnAuth: true,
			loginTemplate: true,
		},
	},
	{
		path: '/accept/:id/:token',
		name: 'accept',
		component: () => import('../views/Accept.vue'),
		meta: {
			title: 'Aftale til underskrift',
			requiresUnAuth: true,
			acceptTemplate: true,
		},
	},
	{
		path: '/action',
		name: 'action',
		component: () => import('../views/Action.vue'),
		meta: {
			loginTemplate: true,
		},
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach(async (to, from, next) => {
	const { project, subscription } = useState();
	const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
	const requiresUnAuth = to.matched.some((record) => record.meta.requiresUnAuth);
	const requiresAdminAuth = to.matched.some((record) => record.meta.requiresAdminAuth);
	const isAuthenticated = auth.currentUser;
	const isAdmin = isAuthenticated ? await auth.currentUser.getIdTokenResult().then((idTokenResult) => !!idTokenResult.claims.admin) : false;
	const requiresProject = to.matched.some((record) => record.meta.requiresProject);
	const requiresSubscription = to.matched.some((record) => record.meta.requiresSubscription);
	const isProjectSelected = project.value.id;
	const hasTitle = to.matched.slice().reverse().find((record) => record.meta && record.meta.title);
	const loginTemplate = to.matched.some((record) => record.meta.loginTemplate);
	const acceptTemplate = to.matched.some((record) => record.meta.acceptTemplate);
	const createProjectPage = to.matched.some((record) => record.name === 'create-project');
	const projects = isAuthenticated ? await projectsCollection().orderBy('viewed', 'desc').get() : { size: 0 };
	const hasActiveSubscription = isAuthenticated && subscription.value.isActive;

	if (requiresAuth && !isAuthenticated) next({ name: 'login' });
	else if (requiresProject && !isProjectSelected) next({ name: 'projects' });
	else if (requiresUnAuth && isAuthenticated) next({ name: 'dashboard' });
	else if (requiresAdminAuth && !isAdmin) next({ name: 'dashboard' });
	else if (requiresSubscription && !hasActiveSubscription) next({ name: 'settings' });
	else if (createProjectPage && projects.size >= 1 && !isAdmin) next({ name: 'projects' });
	else next();

	if (loginTemplate) document.body.classList.add('login-template');
	else document.body.classList.remove('login-template');

	if (acceptTemplate) document.body.classList.add('accept-template');
	else document.body.classList.remove('accept-template');

	if (hasTitle) document.title = `${hasTitle.meta.title} | Selvbygger+`;
});

export default router;
