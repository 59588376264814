<template>
    <header>
        <IconButton to="/projects" icon="initials"/>
        <span>{{ title }}</span>
        <IconButton to="/settings" icon="cog"/>
    </header>
</template>

<script>
import IconButton from '@/components/buttons/IconButton.vue';

export default {
    name: 'Header',
    components: {
        IconButton,
    },
    props: {
        title: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="less" scoped>
@import "../assets/css/variables";

header {
    font-size: 1.8rem;
    padding: 0 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media @laptop-screen {
        display: none;
    }

    span {
        color: rgb(var(--primary-color));
        font-weight: var(--semibold-weight);
    }

    a {
        font-size: var(--size-icon);

        svg {
            color: rgb(var(--dark-grey-color));
        }
    }
}
</style>
