import { createApp } from 'vue';
import { auth } from '@/utils/firebase';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import useState from './store';

let app = '';

auth.onAuthStateChanged(async (fbUser) => {
	if (!app) {
		app = createApp(App);
		app.use(router);
		app.mount('body');

		if (fbUser) {
			const localStorage = JSON.parse(window.localStorage.getItem(fbUser.uid));

			if (localStorage) {
				const { initState } = useState();
				await initState(fbUser.uid);
			} else {
				await auth.signOut();
				await router.push({ name: 'login' });
			}
		}
	}
});
