<template>
    <section :class="{ 'separate' : !addMark }">
        <mark v-if="addMark" v-show="show" :style="{transform: `translate3d(${left}px, ${top}px, 0)`}" ref="mark"></mark>
        <router-link v-for="(item, index) in menuItems" :to="item.path" :ref="item.name" :key="index">
            <Icon :name="item.meta.icon"/>
            <span>{{ item.meta.title }}</span>
        </router-link>
    </section>
</template>

<script>
import Icon from '@/components/Icon.vue';

export default {
    name: 'Menu',
    components: {
        Icon,
    },
    props: {
        addMark: {
            type: Boolean,
            default: false,
        },
        menuItems: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            top: 0,
            left: 0,
            show: this.addMark,
        };
    },
    mounted() {
        this.setMarker(this.$router.currentRoute.value.meta.id);
        this.$refs.mark.style.setProperty('--menu-items', this.menuItems.length.toString());
    },
    watch: {
        $route(to) {
            this.setMarker(to.meta.id);
        },
    },
    methods: {
        setMarker(page) {
            this.show = !!this.$refs[page]?.$el;
            this.top = this.$refs[page]?.$el.offsetTop;
            this.left = this.$refs[page]?.$el.offsetLeft;
        },
    },
};
</script>

<style scoped lang="less">
@import "../assets/css/variables";

section {
    position: relative;
    display: flex;

    @media @laptop-screen {
        display: block;
        margin: 65px 0;
    }

    &.separate a {
        max-width: 230px;
        background: rgb(var(--light-grey-color));

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    a {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: var(--menuitem-height);
        border-radius: 12px;
        position: relative;
        font-size: 1.8rem;

        @media @laptop-screen {
            justify-content: start;

            svg {
                width: 31%;
            }
        }

        &[href="/"] {
            display: none;

            @media @laptop-screen {
                display: flex;
            }
        }

        span {
            display: none;

            @media @laptop-screen {
                display: inherit;
            }
        }

        svg {
            font-size: var(--size-icon);
        }
    }

    mark {
        --menu-items: 1;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: calc(100% / 4);
        background: rgb(var(--white-color));
        border-radius: 12px;
        transition: transform var(--time-transition) var(--cubic-transition);

        @media @laptop-screen {
            height: calc(100% / var(--menu-items));
            width: 100%;
        }
    }
}
</style>
