<template>
    <Header :title="title"/>
    <Navigation/>
    <router-view/>
    <Dialog v-if="updateExists">
        <template #title>Opdatering tilgængelig</template>
        <template #default>
            <p>Selvbygger+ platformen er blevet opdateret siden sidst du var logget ind.</p>
            <p>Tryk på opdater og få de nyeste funktioner.</p>
        </template>
        <template #actions>
            <ActionButton icon="refresh" label="Opdater" :small="true" @click="refreshApp"/>
        </template>
    </Dialog>
</template>

<script>
import Header from '@/components/Header.vue';
import Navigation from '@/components/Navigation.vue';
import update from '@/utils/update';
import Dialog from '@/components/Dialog.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';

export default {
    name: 'Home',
    components: {
        Header,
        Navigation,
        Dialog,
        ActionButton,
    },
    data() {
        return {
            title: null,
            name: null,
        };
    },
    mixins: [update],
    watch: {
        $route(to) {
            this.title = to.meta.title;
        },
    },
};
</script>
