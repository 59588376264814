<template>
    <Teleport to="body">
        <transition name="dialog">
            <article id="dialog-overlay">
                <section>
                    <h2><slot name="title"/></h2>
                    <slot/>
                    <footer v-if="hasActions">
                        <slot name="actions"/>
                    </footer>
                </section>
            </article>
        </transition>
    </Teleport>
</template>

<script>
export default {
    name: 'Dialog',
    setup(props, { slots }) {
        const hasActions = !!slots.actions;

        return { hasActions };
    },
};
</script>

<style lang="less">
@import '../assets/css/variables';

article#dialog-overlay {
    --background-opacity: 60%;
    --background-color: var(--black-color);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: var(--container-padding);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(var(--background-color), var(--background-opacity));
    z-index: 30;
    animation: background-fade-in var(--time-transition) var(--cubic-transition);

    &.dialog-leave-active {
        animation: background-fade-out var(--time-transition) var(--cubic-transition);

        section {
            animation: section-drop-back var(--time-transition) var(--cubic-transition);
        }
    }

    section {
        transform: scale(1);
        opacity: 1;
        background: rgb(var(--white-color));
        box-shadow: 0 10px 20px rgba(var(--black-color), 10%);
        padding: 40px;
        border-radius: 15px;
        width: 360px;
        animation: section-drop-up var(--time-transition) var(--cubic-transition);

        h2 {
            font-weight: var(--semibold-weight);
            margin: 0 0 1.3rem;
            font-size: 2.4rem;
            line-height: 1.1;
        }
    }

    footer {
        margin-top: 34px;

        @media @laptop-screen {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        button {
            width: 100%;
            white-space: nowrap;
            margin-top: 10px;

            @media @laptop-screen {
                width: calc(50% - 8px);
            }
        }
    }
}

@keyframes section-drop-up {
    0% {
        transform: scale(0.7);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes section-drop-back {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(0.7);
        opacity: 0;
    }
}
</style>
