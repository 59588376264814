<template>
    <nav>
        <Logo/>
        <Menu v-if="isSelectedProject" :menu-items="menuItems" :add-mark="true"/>
        <section class="settings">
            <IconButton to="/projects"
                        data-tooltip="Projektdata – her kan du redigere den overordnede tidsplan samt det samlede budget"
                        icon="initials"/>
            <IconButton v-if="isAdmin" data-tooltip="Admin Panel" to="/admin" icon="shield"
                        @click.prevent="onAdminPanelClick"/>
            <IconButton v-else data-tooltip="FAQ og How to-videoer" to="https://selvbyggerplus.dk/faq/"
                        icon="question"/>
            <IconButton to="/settings" data-tooltip="Indstillinger" icon="cog"/>
        </section>
    </nav>
</template>

<script>
import Logo from '@/components/Logo.vue';
import IconButton from '@/components/buttons/IconButton.vue';
import Menu from '@/components/Menu.vue';
import useState from '@/store';
import router from '@/router';
import { computed, onMounted, ref } from 'vue';
import { auth, httpsRequest } from '@/utils/firebase';

export default {
	name: 'Navigation',
	components: {
		Logo,
		IconButton,
		Menu,
	},
	setup(props) {
		const { project, guest, initState, clearGuest } = useState();
		const isAdmin = ref(false);
		const token = ref(null);
		const isSelectedProject = computed(() => !!project.value.id);
		const placement = props.admin ? 'admin' : 'menu';
		const menuItems = router.getRoutes()
			.filter((route) => route.meta.placement === placement)
			.sort((a, b) => a.meta.order - b.meta.order);

		onMounted(async () => {
			if (guest) {
				const isGuestAdmin = await httpsRequest.httpsCallable('isUserAdmin');
				const isGuestAdminResponse = await isGuestAdmin(guest.value);
				isAdmin.value = isGuestAdminResponse.data;

				const createCustomToken = await httpsRequest.httpsCallable('createCustomToken');
				const response = await createCustomToken(guest.value);
				token.value = response.data.token;
			} else {
				const tokenResult = await auth.currentUser.getIdTokenResult();
				isAdmin.value = !!tokenResult.claims.admin;
			}
		});

		return { isSelectedProject, menuItems, isAdmin, guest, token, initState, clearGuest };
	},
	props: {
		admin: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		async onAdminPanelClick() {
			if (this.guest) {
				const userCredential = await auth.signInWithCustomToken(this.token);
				await this.clearGuest();
				await this.initState(userCredential.user.uid);
				await this.$router.push('/admin');
			} else {
				await this.$router.push('/admin');
			}
		},
	},
};
</script>

<style lang="less">
@import '../assets/css/variables';

nav {
    background: rgb(var(--light-grey-color));
    border-radius: 15px;
    margin: 10px;
    padding: 5px;
    box-sizing: border-box;
    z-index: 20;
    box-shadow: 0 0 20px rgba(var(--semi-light-grey-color), 30%);

    @media @laptop-max-screen {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
    }

    @media @laptop-screen {
        padding: 15px;
        margin: 20px;
        display: flex;
        flex-direction: column;
    }

    .logo {
        display: none;

        .tagline {
            color: rgb(var(--primary-color));
        }

        @media @laptop-screen {
            display: block;
            margin: 45px 10px 0 10px;
        }
    }

    section.settings {
        display: none;

        a {
            font-size: 1.8rem;

            svg {
                font-size: var(--size-icon);
            }

            &[data-tooltip]:before, &[data-tooltip]:after {
                transform: translate(-50%, ~'calc(-100% + 10px)');
            }

            &[data-tooltip]:first-child:before {
                transform: translate(-10%, ~'calc(-100% + 10px)');
            }
        }

        @media @laptop-screen {
            display: flex;
            justify-content: space-between;
            margin-top: auto;

            a.router-link-active {
                background: rgb(var(--white-color));
                color: rgb(var(--primary-color));
            }

            a[data-tooltip]:hover {
                &:before, &:after {
                    opacity: 1;
                }
            }

            a[data-tooltip]:after {
                left: 50%;
            }
        }
    }
}

header a,
nav a {
    transition: color var(--time-transition) var(--cubic-transition);

    @media @laptop-screen {
        &:hover {
            color: rgb(var(--primary-color));
            // background: rgba(var(--white-color), 50%);
        }
    }

    &.router-link-exact-active {
        color: rgb(var(--primary-color));
        font-weight: var(--semibold-weight);
    }
}

</style>
